import gql from 'graphql-tag'

export default gql`{
  id
  areas
  category
  creatorType
  description
  difficultyLevel
  duraction
  entity
  image
  openResponse
  positions
  price
  pub
  questions {
    question
    objective
    implication
    alternatives {
      answer
      value
    }
    totalCorrect
    percentage
    picture
  }
  status
  reason
  rejection
  title
  type
  user {
    id
    name
    office
    photo
  }
}`