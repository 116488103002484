<template>
  <div class="pagination">
    <v-btn
      :disabled="isDisabledBack(active)"
      @click="$emit('input', (active - 1))"
      class="btn"
      color="white"
    >
      &laquo;
    </v-btn>
    <v-btn
      v-for="position in length"
      :key="position"
      class="btn mx-1"
      :color="position === active ? 'primary' : 'white'"
      @click="$emit('input', position)"
    >
      {{position}}
    </v-btn>
    <v-btn
      :disabled="isDisabledNext(active)"
      @click="$emit('input', (active + 1))"
      class="btn"
      color="white"
    >
      &raquo;
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TestPagination',
  props: {
    active: { type: Number },
    length: { type: Number },
    totalVisible: { type: Number }
  },
  methods: {
    isDisabledBack (position) {
      return position === 1
    },
    isDisabledNext (position) {
      return position === this.length
    }
  }
}
</script>

<style scoped>
.pagination {
  display: inline-block;
  text-align: center !important;
  align-items: center;
  display: inline-flex;
  list-style-type: none;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  width: 100%;
}

.pagination .btn {
  min-width: 34px;
}
</style>